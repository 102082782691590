/* All css in one file, no seperate files please */
/* sections bana dena */
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans&family=Major+Mono+Display&family=Poppins:wght@200&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
  --color: #000000;
  --yellow: #f8ef12;
  --red: #f0664a;
  --blue: #73c1e4;
  color: var(--color);
  --grad1: linear-gradient(98.91deg, #f0664a 12.81%, #f9f49b 100%) !important;
  --grad2: linear-gradient(
    98.91deg,
    #00b0ff 0%,
    #5abdea 36.98%,
    #73c1e4 100%
  ) !important;
  font-family: 'Montserrat', sans-serif;
}

body {
  background: linear-gradient(to top, #f9f49b, #fff) !important;
}
/* Navbar */

.navbar-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid red;
  height: 70px;
  width: 100%;

  /* display: flex; */
  /* justify-content: center; */
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(115, 193, 228, 0.5) 0%,
    rgba(242, 235, 61, 0.5) 8.33%,
    rgba(242, 235, 61, 0.5) 94%,
    rgba(240, 102, 74, 0.5) 100%
  );
}

.navbar-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000 !important;
  border-radius: 5px;
}
.profile-photo {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 100px;
}

.navbar-logo {
  width: 20vw;
  border: 1px solid green;
  height: 100%;
  font-family: 'Major Mono Display', monospace;
  font-size: 60px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: logofade linear 5s infinite;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.navbar-logo-alt {
  width: 20vw;
  border: 1px solid green;
  height: 100%;
  font-family: 'Major Mono Display', monospace;
  font-size: 40px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  animation: altfade linear 5s infinite;
  transform: translate(-50%, 0%);
}

@keyframes logofade {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes altfade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #111111; */
  overflow-x: hidden;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* login */

.login-main {
  width: 250px;
}

/* landing */
.landing-wrap {
  width: 100vw;
  display: flex;
  justify-content: center;
  border: 10px solid #000000;
  padding-top: 10vh;
}

.landing-main {
  width: 50vw;
  border: 2px solid green;
  height: 50vh;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.landing-main > div {
  border: 1px solid #000000;
}

.landing-big-text {
  font-size: 60px;
}

.landing-desc {
  font-size: 20px;
}

/* landing modal styles */
.image-select {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.image-select-overlay {
  opacity: 0;
  transition: all 0.25s linear;
  position: absolute;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
}

.image-select:hover > .image-select-overlay {
  transition: all 0.25s linear;
  opacity: 1;
}

.image-select > img {
  position: relative;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
/* user page styles here */

.user-main {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  /* margin-right: 20px;
  margin-left: 20px; */
  /* background: yellow; */
}
/* info panel styles */

.panel-main {
  /* width: 30%; */
  /* width: 100%; */
  border-radius: 20px;
  height: min-content;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.75);
  margin: 1.5rem;
  /* gap: 20px; */
}

.msg-head {
  font-size: 40px;
}

/* .panel-image {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
} */

.overlay {
  opacity: 0;
  color: white;
  position: absolute;
  top: 50% !important;
  left: 30% !important;
  transition: opacity 0.5s ease-in-out;
}

.panel-image:hover .overlay {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.panel-image:hover .user-image {
  cursor: pointer;
  opacity: 0.1;
  transition: opacity 0.5s ease-in-out;
}

.panel-image:hover .panel-text {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}
.panel-name {
  font-size: 30px;
  margin-bottom: 10px;
}

.panel-email {
  font-size: 20px;
}

.landing-image {
  filter: invert(1);
}

/* user message stream styles here */
.msg-main {
  width: 60%;
  box-shadow: 5px 5px 3px 0 rgba(240, 102, 74, 0.5);
  border-radius: 20px;
  /* padding: 20px; */
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 78vh;
  overflow-y: scroll;
  position: relative;
  flex-grow: 1;
  margin: 1.5rem;
}

.msg-modded {
  width: 90%;
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  /* padding: 20px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 20px;
  height: 72vh;
  overflow-y: scroll;
  position: relative;
}

.msg-modded::-webkit-scrollbar {
  width: 12px;
}

.msg-modded::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 10px;
}
.msg-modded::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  /* background-color: red; */
  /* border: 2px solid red; */
}

.preview-img {
  /* max-width: 60vw; */
  max-height: 60vh;
}

.img-preview-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
}

.msg-main::-webkit-scrollbar {
  width: 12px;
}

.msg-main::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  border-radius: 10px;
}
.msg-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  /* background-color: red; */
  /* border: 2px solid red; */
}

.email-tag {
  transition: 0.5s all;
}

.email-tag:hover {
  background-color: #fff !important;
}

/* message box style */
.mb-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid #0085ff;
  box-shadow: 4px 2px 5px rgba(19, 181, 255, 0.25);
  border-radius: 30px;
  /* box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.75); */
}

.mb-tail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mb-avatar {
  width: 40px;
  height: 40px;
  border: 2px solid red;
  border-radius: 20px;
}

.mb-head {
  font-size: 25px;
}

/* user image style */

/* .user-image {
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1;
} */
/* responsive */
@media (max-width: 900px) {
  .user-main {
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }
  .panel-main {
    width: 100%;
    max-width: 400px;
    align-self: center;
    margin-bottom: 20px;
  }

  .msg-main {
    width: 100%;
  }
  .panel-image {
    align-self: center;
  }

  .temp-contain {
    margin-bottom: 30px;
  }
}

.temp-contain {
  flex-direction: column;
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.custom-send-message {
  /* position: fixed; */
  margin-top: 20px;
  margin-bottom: 20px;
}

.messages-sent {
  right: 7.5%;
  bottom: 18%;
}

/* .custom-send-message::after {
  content: 'Send a message';
  opacity: 0;
  position: absolute;
  color: red;
  font-size: 12px;
  width: max-content;
  transform: translate(0px, 0px);
  transition: 0.5s all;
}

.custom-send-message:hover::after {
  opacity: 1;
} */

.navbar-header {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

.footer-div-custom {
  order: 99;
}

.home-elange-img {
  height: 7rem;
}

@media (max-width: 820px) {
  .navbar-header {
    display: grid;
    grid-template-columns: 20% 20% 60%;
    padding: 5px !important;
  }
  .hide-onlow {
    display: none !important;
  }
  .image-onlow {
    transform: scale(0.75);
  }
}

@media (max-width: 1200px) {
  .image-emoji {
    display: none;
  }

  .home-main-box {
    flex-direction: column;
    height: auto !important;
  }
  .home-main-box > div {
    padding: 20px 0 20px 0;
    width: 100% !important;
  }
  .text-home {
    text-align: center !important;
  }
  .home-elange {
    font-size: 60px !important;
  }
  .home-elange-img {
    height: 5rem;
  }

  .home-arc-img {
    width: 80%;
  }

  .glogin {
    order: -99 !important;
    padding: 20px;
  }
}

@media (max-width: 800px) {
  .footer-custom {
    display: grid !important;
    grid-template-columns: repeat(2, 50%);
  }

  .footer-div-custom {
    order: 0;
  }

  .footer-arc {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-devsoc {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-melange {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 20px;
  }
}
